.header {
  display: flex !important;
  height: 80px;
  flex-direction: row !important;
  flex-wrap: wrap;
  align-items: center;
  background: #2c3a47 !important;

  .logo {
    height: 100%;
    width: 150px;
    margin-left: 20px;

    a {
      width: 100%;
      height: 70%;
      position: relative;
      top: 15%;
      display: block;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .flex_container {
    display: flex;
    flex: 1;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;

    &.previewmode {
      pointer-events: none;
    }

    .leftWrapper {
      display: flex;
      align-items: center;
      margin-left: 10%;
      font-weight: bold;
      .toggleSidebar {
        display: none;
      }
    }
    .left-label {
      color: #fff;
      font-size: 1.3rem;

      margin-right: 0.5rem;
    }
    .left {
      border: 1px solid #fff;
      border-radius: 5px;
      background-color: $dark;
      margin: 0 0 0 16;
      padding: 4px;
      box-shadow: 1px 1px #000000;

      .left_side_info {
        cursor: pointer;
        display: flex;
        align-items: center;
        // margin-left: 20px;
        text-align: center;
        color: #fff;
      }

      .left_side_info label {
        cursor: pointer;
      }

      .btn_left {
        font-size: 14px;
        color: red;
      }

      .new {
        position: absolute;
        width: 14px;
        height: 14px;
        font-weight: normal;
        text-transform: none;
        font-size: 6px;
        background: #ff5f7b;
        border-radius: 100%;
        line-height: 14px;
        left: 22px;
        top: 0px;
      }
    }

    .right {
      display: flex;
      text-align: center;
      align-items: center;

      .search_all {
        position: relative;

        input {
          text-align: center;
          vertical-align: middle;
          width: 100%;
          height: 40px;
          border: none;
          border-radius: 30px;
          background: white;
          color: black;
          box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
          font-size: 18px;
          padding: 0 50px 0 30px;
        }

        .searchIcon {
          width: 20px;
          height: 30px;
          color: #000000;
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
        }
      }

      .right_btn {
        text-align: center;
        color: $white;
        padding: 0;
        width: 70px;
        height: 50px;
      }

      .right_btn_previewmode {
        text-align: center;
        color: #808080;
        padding: 0;
        width: 70px;
        height: 50px;
      }
    }
  }
}

.list_dialog {
  .MuiPaper-root {
    background: #283440;
    border-radius: 0;
    box-shadow: none;
    max-width: 250px;
    width: 250px;

    ul {
      a {
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        color: #fff;

        .count {
          font-size: 14px;
          position: relative;
        }

        .count.new {
          &::before {
            content: "";
            display: block;
            width: 5px;
            height: 5px;
            border-radius: 100%;
            background: #ff5f7b;
            position: absolute;
            top: 50%;
            left: -13px;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

.profile_dialog {
  .MuiPaper-root {
    background: #283440;
    border-radius: 0;
    box-shadow: none;
    max-width: 260px;
    width: 260px;
    height: 248px;

    ul {
      padding: 20px 0;
    }

    select {
      font-size: 20px;
      border: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background: #283440;
      color: #fff;

      option {
        color: #fff;
      }
    }

    .menu_item {
      height: 42px;
      font-size: 20px;
      color: #fff;

      svg {
        margin-right: 10px;
      }
    }

    .menu_item2 {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .signOut {
      padding: 0;
      margin: 0 auto;
      display: block;
      text-align: center;
      width: 84%;
      height: 30px;
      color: #fff;
      background: #1ab3aa;
      border-radius: 5px;
      line-height: 30px;
      font-size: 18px;
      margin-top: 10px;
    }
  }
}

@media (max-width: 1024px) {
  .header {
    .flex_container {
      .leftWrapper {
        .toggleSidebar {
          display: block;
        }
      }
    }
  }

  #sidebar {
    left: -250px;

    &.toggled {
      left: 0;
    }
  }
}
