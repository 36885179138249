// global variables
$dark: #1b202a;
$darkGray: #2c3a47;
$lightGray: #babbbf;
$aqua: #1ab3aa;
$white: #fff;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  &::before,
  &::after {
    box-sizing: border-box;
  }
}

// @font-face {
//     font-family: Inter;
//     // src: local('Inter'), url(./fonts/Inter-Light.ttf) format('truetype');
//     src: url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300&display=swap');
// }

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
body {
  background: $dark;
  font-family: 'Inter', 'sans-serif';
  //font-family: "Myriad Web Pro";
}

