.ts_container {
  padding-top: 80px;

  .ts_content {
    display: flex;
    flex-wrap: wrap;
    .title_green {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 40px;
      color: #1ab3aa;
      text-transform: uppercase;
      margin: 0 107px 43px 0;
      font-family: Inter;
      font-size: 36px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
    .title {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 40px;
      color: #fff;
      font-size: 24px;

      .label_required {
        margin-bottom: 30px;
        color: #ff5f7b;
        font-size: 12px;
      }

      .label_date {
        color: #fff;
        font-size: 14px;
      }
    }

    .input_container {
      flex: 0 0 50%;
      max-width: 50%;

      .field_container {
        max-width: 500px;
        margin: 0 auto;
        margin-bottom: 15px;

        .label {
          color: #fff;
          font-size: 14px;

          &.required {
            &::after {
              content: "*";
              color: #ff5f7b;
              font-size: 14px;
              display: inline-block;
              margin-left: 2px;
            }
          }
          .required-with-text {
            color: #ff5f7b;
            font-size: 14px;
            display: inline-block;
            margin-left: 2px;
          }
        }

        input {
          border: 0;
          height: 30px;
          border-radius: 5px;
          padding: 0 15px;
          background: #fff;
          width: 100%;
        }

        input[type="text"]:disabled {
          background: #dddddd;
          color: #00000061;
        }

        .textArea {
          width: 100%;
          border-radius: 5px;
          padding: 15px;
          font-family: inherit;
        }
      }

      .searchContainer {
        max-width: 500px;
        margin: 0 auto;
        margin-bottom: 15px;

        .wrapper {
          position: relative;
          width: 90%;
        }

        .label {
          color: #fff;
          font-size: 14px;

          &.required {
            &::after {
              content: "*";
              color: #ff5f7b;
              font-size: 14px;
              display: inline-block;
              margin-left: 2px;
            }
          }
        }

        label {
          font-size: 14px;
          color: #e0dfe7;
          margin-bottom: 5px;
          &.required {
            &::after {
              content: "*";
              color: #ff5f7b;
              font-size: 14px;
              display: inline-block;
              margin-left: 2px;
            }
          }
        }

        .popup_text {
          height: 30px;
          width: 100%;
          cursor: pointer;
          background: #fff;
          border-radius: 5px;
          line-height: 30px;
          padding: 0 15px;
        }

        .search_btn {
          padding: 0;
          position: absolute;
          bottom: 0;
          right: 0;
          height: 30px;
          width: 30px;
          text-align: center;
          color: #000;
        }

        .addBox {
          padding: 0;
          bottom: 0;
          height: 30px;
          width: 30px;
          color: #fff;
          background: #fff;
          min-width: auto;
          right: -48px;
          position: absolute;
          color: #000;
        }
      }
    }

    .button_wrapper {
      flex: 0 0 50%;
      max-width: 50%;
      margin-top: 50px;

      .image_wrapper {
        width: 250px;
        height: 250px;
        margin: 0 auto;
        overflow: hidden;
        border-radius: 5px;

        .disable-link {
          pointer-events: none;
        }

        img {
          display: block;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        img[disabled] {
          opacity: 0.4;
        }
      }

      .image_wrapper2 {
        width: 400px;
        margin: 0 auto;
        overflow: hidden;
        border-radius: 5px;

        img {
          display: block;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .savebuttoninfots {
        width: 100%;
        display: block;
        text-align: center;
        margin-top: 50px;
        margin-bottom: 15px;
        margin-left: -40px;
      }

      .previewinfots {
        width: 150px;
        height: 50px;
        padding: 0;
        color: #fff;
        background: #1ab3aa;
        font-size: 24px;
        text-transform: none;
      }

      .editinfots {
        width: 150px;
        height: 50px;
        padding: 0;
        color: #fff;
        background: #1ab3aa;
        font-size: 24px;
        right: -80px;
        text-transform: none;
      }

      .save {
        width: 250px;
        height: 50px;
        margin: 0 auto;
        display: block;
        margin-top: 30px;
        background: #c2c2c2;
        color: #fff;
        font-size: 24px;
        text-transform: none;
      }

      .save_confirm {
        width: 250px;
        height: 50px;
        margin: 0 auto;
        display: block;
        margin-top: 30px;
        background: #1ab3aa;
        color: #fff;
        font-size: 24px;
        text-transform: none;
      }
    }
  }
}

.inquiryForm {
  max-width: 500px;
  margin: 0 auto;

  .button-container {
    max-width: 500px;
    margin: 0 auto;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-end;
    text-align: center;
    .button-disabled {
      margin-left: 12px;
      border: 0;
      height: 45px;
      min-width: 160px;
      color: #000;
      padding: 0 32px;
      background: "#C2C2C2";
      font-weight: 600;
      font-size: 18px;
      cursor: pointer;
      border-radius: 25px;
    }
    .button-approve {
      margin-left: 12px;
      border: 0;
      height: 45px;
      min-width: 160px;
      color: #000;

      padding: 0 32px;
      background-color: #19b2ab;
      transition: background 0.2s linear;
      color: #1b202c;
      font-weight: 600;
      font-size: 18px;
      cursor: pointer;
      border-radius: 25px;
      &:hover {
        background-color: lighten(#19b2ab, 10%);
      }
    }
    .button-delete {
      margin-left: 12px;
      display: flex;
      align-items: center;
      height: 45px;
      min-width: 160px;
      color: #000;
      padding: 0 32px;
      border-radius: 25px;
      border: none;
      background: #fff;
      font-weight: 600;
      font-size: 18px;
      cursor: pointer;
      transition: background 0.2s linear;
      svg {
        margin-right: 10px;
        color: #19b2ab;
      }
      &:hover {
        background-color: lighten(#19b2ab, 10%);

        svg {
          color: #fff;
        }
      }
    }
  }
}
