.home {
  margin-top: 110px;
  padding-left: 330px;

  .new-report-container {
    padding: 2rem;
    color: white;
    &--information {
      font-size: 1.2rem;
      border-bottom: 0.3rem solid gray;
      margin-bottom: 0.5rem;
    }
    .new-report-row {
      // border: 1px solid orange;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      padding: 10px;
      text-align: center;
      align-items: center;
      .new-report-title {
        text-transform: uppercase;
        margin-right: 20px;
      }
      .status-label {
        display: flex;
        flex-wrap: wrap;
        max-width: 100%;
        .report_status {
          font-size: 11px;
          color: #fff;
          align-items: center;
          text-align: center;
          min-width: 100px;
          height: 20px;
          background: #ff5f7b;
          margin-right: 5px;
          margin-top: 5px;
          margin-bottom: 3px;
          border: none;
          border-radius: 5px;
        }
      }
    }
  } 
}
